import React from 'react'
import Img from 'gatsby-image'


class Record extends React.Component {
    constructor(props){
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.state = {
        active: false,
      };
    }

    handleClick= () => {
      const currentState = this.state.active;
      this.setState({ active: !currentState });
      this.props.click(this.props.id)
    };

    render(){
      return(
        <div className={this.state.active ? 'record-cover open': 'record-cover'} onClick={this.handleClick}>
          <Img fixed={this.props.data.frontmatter.frontImage.childImageSharp.fixed} className='front'/>
          <Img fixed={this.props.data.frontmatter.backImage.childImageSharp.fixed} className='back'/>
          <p className={this.state.active ? 'title open': 'title'} >{this.props.data.frontmatter.title}</p>
          <p className={this.state.active ? 'artist open': 'artist'} >{this.props.data.frontmatter.artist !== 'x' ? this.props.data.frontmatter.artist : ''}</p>
        </div>
      )
  }
}

export default Record
