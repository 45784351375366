import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Record from '../components/record'
import SEO from '../components/seo'

class IndexPage extends React.Component {

  constructor(){
    super();
    this.onClick = this.onClick.bind(this)
    this.state = {
      active: false,
    };
  }
  onClick(id){
    console.log(id)
  }

  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges
    return (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <div className="record-grid-listing pb-5 grid">
        {posts.map(({ node }) => {
          return (
            <Record data={ node } key={node.id} click={this.onClick} id={node.id} />
          )
        })}
        </div>
      </Layout>
    )
}
}

export default IndexPage

export const pageQuery = graphql`
query {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___artist, frontmatter___released, frontmatter___title] }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            artist
            frontImage{
              childImageSharp {
                fixed(width: 250) {
                    ...GatsbyImageSharpFixed
                }
              }
            }
            backImage{
              childImageSharp {
                fixed(width: 250) {
                    ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
